"use strict"
##global Response##
##global console##
isMobile = undefined

Homepage =
  init: ->
    # @ResponseCreate()
    
    @loadPage()

    # @scrollbarEffect()
    $('img.syr-footer-logo').lazyload()

  addBlankDiv: (className) ->
    div = document.createElement('div')
    div.className = className
    document.body.appendChild(div)

  widthDetect: ->

    # breakpoint($tbMobile)
    isMobile = ( $('.view-width-tester').css('width') is '10px' ) ? true : false


  responsiveH: ->
    # setTimeout ( ->
    this.widthDetect()
    windowHeight = $(window).height()
    windowWidth = $(window).width()
    # isMobile = windowWidth < 861
    # isTablet = windowWidth < 961
    bannerContentHeight = $('#syr-top-banner-content').outerHeight()
    bannerImageHeight = $('.syr-index-top-banner-figure').outerHeight()      
    # sliderHeight = if isMobile then $('.syr-index-top-banner-figure').outerHeight() + $('#syr-top-banner-content').outerHeight() + $('#syr-index-watch-video').outerHeight() + $('.index-buy-tickets-btn').outerHeight() + 25 else Math.max(bannerContentHeight, bannerImageHeight)

    sliderHeight = if isMobile then $('.syr-index-top-banner-figure').outerHeight() + $('#syr-top-banner-content').outerHeight() + $('#syr-index-watch-video').outerHeight() + $('.index-buy-tickets-btn').outerHeight() + $('#top-video-box-figure').outerHeight() + 12 else 'auto'
    # console.log(Math.max(bannerContentHeight, bannerImageHeight))
    # console.log(bannerImageHeight)
    # console.log(bannerContentHeight)
    IndexbuyTicketsBtnWidth = if isMobile then windowWidth - 30 else 156
    # $('#syr-index-top-banner-shenyun').css
    #   height: sliderHeight  
    # $('#syr-index-top-banner-symphony').css
    #   height: sliderHeight
    $('#syr-event-top-banner-symphony').css
      height: sliderHeight
    $('#syr-event-top-banner-shenyun').css
      height: sliderHeight
    $('.index-buy-tickets-btn').css
      width: IndexbuyTicketsBtnWidth

  dateHeightCalculate: ->
    $('.general-event-list-item .dates').each ->
      newHeight = $(this).next('.location-actions-wrapper').outerHeight()
      $(this).css
        height: newHeight

  loadPage: ->
    $ ->
      Homepage.addBlankDiv('view-width-tester')

      Homepage.responsiveH()
    $(window).on
      load: ->
        Homepage.responsiveH()
#        Homepage.dateHeightCalculate()
      scroll: ->
        Homepage.responsiveH()
      resize: ->
        Homepage.responsiveH()
#        Homepage.dateHeightCalculate()

    # Response.ready ->
    #   Homepage.responsiveH()
    # Response.resize ->
    #   Homepage.responsiveH()

  # scrollbarEffect: ->
  #   $('#syr-shows-near-you-wrapper').perfectScrollbar
  #     suppressScrollY: true
  










  
# $ ->
Homepage.init()


