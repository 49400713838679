(($) ->
  "use strict"

  $.fn.quick_photo_ratio = ->
    @each ->
      figure = $(this)
      figure_wrapper = figure.parent()
      wrapper_w = figure_wrapper.outerWidth()
      wrapper_h = figure_wrapper.outerHeight()
      w_origin = figure.outerWidth()
      h_origin = figure.outerHeight()
      heightRatio = wrapper_h / h_origin
      widthRatio = wrapper_w / w_origin
      aspectRatio = w_origin / h_origin
      ratioOrigin = wrapper_w / wrapper_h
      vertical = h_origin > w_origin
      wider = aspectRatio > ratioOrigin
      if wider
        figure.css
          width: 'auto'
          height: wrapper_h
          marginLeft: -(w_origin * heightRatio - wrapper_w) / 2
          marginTop: 0
      else if vertical
        figure.css
          height: wrapper_h
          width: 'auto'
          marginTop: 0
      else
        figure.css
          width: wrapper_w
          height: 'auto'
          marginTop: -(h_origin * widthRatio - wrapper_h) / 2



  $.fn.photoRatio = ->
    _this = @
    fitRatio = ->
      _this.each ->
        figure = $(this)
        figure_wrapper = figure.parent()
        wrapper_w = figure_wrapper.outerWidth()
        wrapper_h = figure_wrapper.outerHeight()
        w_origin = figure.width()
        h_origin = figure.height()
        heightRatio = wrapper_h / h_origin
        widthRatio = wrapper_w / w_origin
        aspectRatio = w_origin / h_origin
        ratioOrigin = wrapper_w / wrapper_h
        wider = aspectRatio > ratioOrigin

        if wider
          figure.css
            width: 'auto'
            height: wrapper_h
            marginLeft: -(w_origin * heightRatio - wrapper_w) / 2
        else
          figure.css
            width: wrapper_w
            height: 'auto'
            marginTop: -(h_origin * widthRatio - wrapper_h) / 2

    $(window).on
      load: -> fitRatio()
      resize: -> fitRatio()
      scroll: -> fitRatio()

    $ ->
      fitRatio()

) jQuery