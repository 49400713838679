(($) ->
  "use strict"
  ##global Response##
  ##global console##

  MainAction =
    init: ->
      @lazyDiv()
      @popupAction()
      $('.general-event-list-item-thumb img').photoRatio()
      @toShowTextarea()
      @focusActive()
      @creatAcount()
      btn = $('.order-summary-share-btn')
      @insertShareBtn(btn)
      @offCanvas()

    lazyDiv: ->
      $('.lazy').lazyload()
      $('#syr-top-banner-content img').lazyload()

    popupAction: ->
      $("[popup='ajax']").fancybox
        type: 'ajax'
      $("[popup='inline']").fancybox
        type: 'inline'
      $("[popup='image']").fancybox
        type: 'image'

      iLightBoxSeting =
        skin: 'mac'
        opacity: 0.85
        callback:
          onOpen: ->
            $('.ilightbox-overlay').css('background-color', '#000')

      $('#syr-index-watch-video').iLightBox(iLightBoxSeting)
      $('#top-video-box-figure').iLightBox(iLightBoxSeting)
      $('.right-col-video-trailer-thumb-a').iLightBox(iLightBoxSeting)
      $('.right-col-video-trailer-thumb-b').iLightBox(iLightBoxSeting)
      $('.right-col-video-trailer-thumb-c').iLightBox(iLightBoxSeting)

      $('#shenyun-header-search-txt').iLightBox
        type: 'inline'
        skin: 'mac'
        keepAspectRatio: false
        overlay:
          opacity: 0.95
        callback:
          onShow: (api, position) ->
            setTimeout ( -> $(api.currentElement).find('.shenyun-top-search-form-input').focus()), 0


    

    _toShowTextarea: (btn) ->
      $.each btn, ->
        theForm = $(this).next()
        $(this).bind 'click', ->
          $(this).fadeOut(10)
          theForm.fadeIn()
    
    toShowTextarea: ->
      btn = $('.order-customer-comments-title')
      MainAction._toShowTextarea(btn)
    


    focusActive: ->
      firstStep = $('#order-fast-payment-left-column')
      firstStepVal = firstStep.find('input')
      secondStep = $('#order-fast-payment-center-column')
      secondStepVal = secondStep.find('input, textarea')
      lastStep = $('#order-fast-payment-right-column')
      lastStepVal = lastStep.find('input, textarea, .sy-white-btn')

      active_me = (focusDiv) ->
        focusDiv.addClass('active')
        focusDiv.nextAll('.active-obj').removeClass('active')
        focusDiv.prevAll('.active-obj').removeClass('active')

      firstStepVal.bind
        click: ->
          focusDiv = firstStep
          active_me(focusDiv)
        focus: ->
          focusDiv = firstStep
          active_me(focusDiv)

      secondStepVal.bind
        click: ->
          focusDiv = secondStep
          active_me(focusDiv)
        focus: ->
          focusDiv = secondStep
          active_me(focusDiv)

      lastStepVal.bind
        click: ->
          focusDiv = lastStep
          active_me(focusDiv)
        focus: ->
          focusDiv = lastStep
          active_me(focusDiv)
          
    

    toggleDisplay: (newForm, checkbox) ->
      _toggleDisplayForm = ->
        eid = checkbox.is(':checked')
        if !eid
          newForm.fadeOut()
        else
          newForm.fadeIn()

      checkbox.bind
        click: ->
          _toggleDisplayForm()
        focus: ->
          _toggleDisplayForm()

    creatAcount: ->
      newForm = $('#password-section')
      checkbox = $('#create-acc-for-later')
      MainAction.toggleDisplay(newForm, checkbox)
    

    insertShareBtn: (btn) ->
      btn.each ->
        paperText = $(this).parent('.tab-pane').find('.small-txt')
        $(this).insertAfter(paperText)
    
    offCanvas: ->
      $('#tb-navbar-toggle').fancybox
        padding: 0
        margin: 0
        closeBtn: false
        # openEffect: 'none'
        # closeEffect : 'none'
        scrolling: 'no'
        wrapCSS: 'off-canvas-menu'
        title: null
        minWidth: '100%'
        minHeight: '100%'
        autoResize: true
        # beforeShow: ->
        #   $(".fancybox-skin").css
        #     backgroundColor: "transparent"
        #     boxShadow: "none"
          # $('.fancybox-overlay').addClass('hidden-for-sm')
        # helpers:
        #   overlay:
        #     css:
        #       background: 'transparent'
        #       zoom: '1'
        
  $ ->
    MainAction.init()

) jQuery